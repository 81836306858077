<template>
  <v-container
    fluid
    class="px-0"
    :style="$vuetify.breakpoint.mobile ? 'margin-bottom: 48px;' : `max-height: ${vuetifyHeight - 140 + 'px'}; overflow-y: hidden; overflow-x: hidden;`"
  >
    <div
      v-if="!$vuetify.breakpoint.mobile"
      style="width: 100%;"
    >
      <CasesFilterBar
        v-model="caseSearch"
        :loading="casesLoading"
        :options="{
          hasAddCase: isSharedWithMe ? false : true
        }"
        :account="account"
        :company="company"
        :group="group"
        :is-company-tab="isCompanyTab"
        :is-group-tab="isGroupTab"
        :user-role="userRole"
        :is-single-acc="isSingleAcc"
        :is-my-tab="isMyTab"
        :is-lavvira-tab="isLavviraTab"
        :is-shared-with-me="isSharedWithMe"
        :actual-tab="actualTab"
        @viewSelection="changeView"
      />
    </div>
    <v-row
      v-if="isMyTab && !cases.length || isCompanyTab && !companyCases.length || isGroupTab && !groupCases.length"
    >
      <v-container
        class="mt-4 ml-4"
        :style="`height: ${vuetifyHeight - 220 + 'px'};`"
      >
        <template v-if="isMyTab && !cases.length">
          <div
            class="center"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              mdi-briefcase-edit
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('cases|status_no_cases') }}</span>
          </div>
        </template>
        <template v-if="isCompanyTab && !companyCases.length">
          <div
            class="center"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              mdi-briefcase-edit
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('cases|status_no_cases') }}</span>
          </div>
        </template>
        <template v-if="isGroupTab && !groupCases.length">
          <div
            class="center"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              mdi-briefcase-edit
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('cases|status_no_cases') }}</span>
          </div>
        </template>
      </v-container>
    </v-row>

    <div v-if="selection && selection === 'all'">
      <v-row
        v-if="sectionCases && ((cases && cases.length) || (companyCases && companyCases.length) || (groupCases && groupCases.length))"
        class="px-3"
      >
        <v-col
          :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
        >
          <v-card
            flat
            :style="$vuetify.breakpoint.mobile ? 'min-height: 300px;' : `height: ${vuetifyHeight - 225 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
          >
            <v-card-text
              style="font-size:1rem"
              class="text-center"
            >
              {{ $t('cases|to_do_cases') }}
            </v-card-text>
            <div
              v-for="c in sectionCases.todoCases"
              :key="c._id"
              class="ma-2"
            >
              <CaseCard
                :case-data="c"
                :actual-tab="actualTab"
                class="my-4"
                @changeStatus="$event => changeStatus(c, $event)"
                @changeFavourite="changeFavourite(c)"
              />
            </div>
          </v-card>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
          :style="`background-color: ${$vuetify.theme.themes.light.background}`"
        >
          <v-card
            flat
            color="background"
            :style="$vuetify.breakpoint.mobile ? 'min-height: 300px;' : `height: ${vuetifyHeight - 220 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
          >
            <v-card-text
              style="font-size:1rem"
              class="text-center"
            >
              {{ $t('cases|in_progress') }}
            </v-card-text>
            <div
              v-for="c in sectionCases.inprogressCases"
              :key="c._id"
              class="ma-2"
            >
              <CaseCard
                :case-data="c"
                :actual-tab="actualTab"
                class="my-4"
                @changeStatus="$event => changeStatus(c, $event)"
                @changeFavourite="changeFavourite(c)"
              />
            </div>
          </v-card>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.mobile ? '12' : '4'"
        >
          <v-card
            flat
            class="fill-height"
          >
            <v-card-text
              style="font-size:1rem"
              class="text-center"
            >
              {{ $t('cases|finished_cases') }}
            </v-card-text>
            <div
              v-for="c in sectionCases.doneCases"
              :key="c._id"
              class="ma-2"
            >
              <CaseCard
                :case-data="c"
                :actual-tab="actualTab"
                class="my-4"
                @changeStatus="$event => changeStatus(c, $event)"
                @changeFavourite="changeFavourite(c)"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-container
      v-if="selection && selection === 'companies'"
      fluid
    >
      <v-card
        flat
        :style="$vuetify.breakpoint.mobile ? 'min-height: 300px;' : `height: ${vuetifyHeight - 225 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
      >
        <v-card-text
          style="font-size:1rem"
          class="text-left"
        >
          {{ $t('cases|cases_of_company_clients') }}
        </v-card-text>
        <v-row
          v-if="casesWithCompanyClient && casesWithCompanyClient.length"
          no-gutters
          justify="start"
        >
          <div
            v-for="(c, idx) in casesWithCompanyClient"
            :key="idx"
            class="pa-2 ma-2"
          >
            <CaseCard
              :case-data="c"
              :actual-tab="actualTab"
              :style="$vuetify.breakpoint.mobile ? '' : 'width: 480px;'"
              @changeStatus="$event => changeStatus(c, $event)"
              @changeFavourite="changeFavourite(c)"
            />
          </div>
        </v-row>
        <v-row v-else>
          <div
            class="center"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              mdi-briefcase-edit
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('cases|status_no_cases') }}</span>
          </div>
        </v-row>
      </v-card>
    </v-container>

    <v-container
      v-if="selection && selection === 'individual_clients'"
      fluid
    >
      <v-card
        flat
        :style="$vuetify.breakpoint.mobile ? 'min-height: 300px;' : `height: ${vuetifyHeight - 225 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
      >
        <v-card-text
          style="font-size:1rem"
          class="text-left"
        >
          {{ $t('cases|cases_of_individual_clients') }}
        </v-card-text>
        <v-row
          v-if="casesWithIndividualClient && casesWithIndividualClient.length"
          no-gutters
          justify="start"
        >
          <div
            v-for="(c, idx1) in casesWithIndividualClient"
            :key="idx1"
            class="pa-2 ma-2"
          >
            <CaseCard
              :case-data="c"
              :actual-tab="actualTab"
              :style="$vuetify.breakpoint.mobile ? '' : 'width: 480px;'"
              @changeStatus="$event => changeStatus(c, $event)"
              @changeFavourite="changeFavourite(c)"
            />
          </div>
        </v-row>
        <v-row v-else>
          <div
            class="center"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              mdi-briefcase-edit
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('cases|status_no_cases') }}</span>
          </div>
        </v-row>
      </v-card>
    </v-container>

    <v-container
      v-if="selection && selection === 'recently_added'"
      fluid
    >
      <v-card
        flat
        :style="$vuetify.breakpoint.mobile ? 'min-height: 300px;' : `height: ${vuetifyHeight - 225 + 'px'}; overflow-y: auto; overflow-x: hidden;`"
      >
        <v-card-text
          style="font-size:1rem"
          class="text-left"
        >
          {{ $t('cases|recently_added_cases') }}
        </v-card-text>
        <v-row
          v-if="recentlyAddedCases && recentlyAddedCases.length"
          no-gutters
          justify="start"
        >
          <div
            v-for="(c, idx2) in recentlyAddedCases"
            :key="idx2"
            class="pa-2 ma-2"
          >
            <CaseCard
              :case-data="c"
              :actual-tab="actualTab"
              :style="$vuetify.breakpoint.mobile ? '' : 'width: 480px;'"
              @changeStatus="$event => changeStatus(c, $event)"
              @changeFavourite="changeFavourite(c)"
            />
          </div>
        </v-row>
        <v-row v-else>
          <div
            class="center"
          >
            <v-icon
              color="primary"
              class="mb-1"
            >
              mdi-briefcase-edit
            </v-icon>
            <span class="ml-2 mb-n1">{{ $t('cases|status_no_cases') }}</span>
          </div>
        </v-row>
      </v-card>
    </v-container>
    <div
      v-if="$vuetify.breakpoint.mobile"
    >
      <CasesFilterBar
        v-model="caseSearch"
        :loading="casesLoading"
        :options="{
          hasAddCase: isSharedWithMe ? false : true
        }"
        :account="account"
        :company="company"
        :group="group"
        :is-company-tab="isCompanyTab"
        :is-group-tab="isGroupTab"
        :user-role="userRole"
        :is-single-acc="isSingleAcc"
        :is-my-tab="isMyTab"
        :is-lavvira-tab="isLavviraTab"
        :is-shared-with-me="isSharedWithMe"
        :actual-tab="actualTab"
        class="bottom-element"
        @viewSelection="changeView"
      />
    </div>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions, mapMutations } from 'vuex'
import { parseCaseStatus } from '@/utils/index'
import CasesFilterBar from './CasesFilterBar.vue'
import CaseCard from './CaseCard.vue'

const sortByUpdated = (items) => items.sort((a, b) => moment.utc(b.updatedAt).diff(moment.utc(a.updatedAt)))
const sortByCreated = (items) => items.sort((a, b) => moment.utc(b.createdAt).diff(moment.utc(a.createdAt)))

export default {
  components: {
    CasesFilterBar,
    CaseCard
  },
  props: {
    group: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    isCompanyTab: {
      type: Boolean,
      default: false
    },
    isGroupTab: {
      type: Boolean,
      default: false
    },
    isMyTab: {
      type: Boolean,
      default: false
    },
    isLavviraTab: {
      type: Boolean,
      default: false
    },
    isSingleAcc: {
      type: Boolean,
      default: false
    },
    isClientAcc: {
      type: Boolean,
      default: false
    },
    isSharedWithMe: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: undefined
    },
    actualTab: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      caseSearch: '',
      selection: localStorage.getItem('casesSectionView') || 'all'
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      cases: state => state.cases.cases,
      sharedWithMeCases: state => state.cases.sharedWithMeCases,
      companyCases: state => state.companyCases.companyCases,
      groupCases: state => state.groupCases.groupCases,
      casesLoading: state => state.cases.loading
    }),
    vuetifyHeight () {
      return this.$vuetify.breakpoint.height
    },
    filteredTabCases () {
      let actualCases = []
      if (this.isMyTab && !this.isSharedWithMe && (this.account.accountType !== 'company' || this.account.accountType !== 'individual')) {
        if (this.cases && this.cases.length) {
          actualCases = this.cases
        }
      }
      if (this.isSharedWithMe) {
        this.sharedWithMeCases.forEach(c => {
          if (c.createdBy._id !== this.account._id) {
            actualCases.push(c)
          }
        })
      }
      if (this.isCompanyTab && this.company) {
        actualCases = this.companyCases
      }
      if (this.isGroupTab && this.group) {
        this.groupCases.forEach(_case => {
          const index = _case.sharedWith.groups.findIndex(gr => gr._id === this.group._id)
          if (index !== -1) {
            actualCases.push(_case)
          }
        })
      }
      if (this.isClientAcc && (this.account.accountType === 'company' || this.account.accountType === 'individual')) {
        actualCases = this.cases
      }
      return actualCases || []
    },
    filteredCases () {
      if (this.caseSearch && this.caseSearch.length) {
        return this.filteredTabCases.filter((c) => {
          return c.caseName.toLowerCase().includes(this.caseSearch.toLowerCase())
        })
      }
      return this.filteredTabCases
    },
    sectionCases () {
      const todoCases = []
      const inprogressCases = []
      const doneCases = []
      this.filteredCases.forEach((c) => {
        if (c.status === 'To do' || c.status === 'todo') todoCases.push(c)
        if (c.status === 'In progress') inprogressCases.push(c)
        if (c.status === 'Done') doneCases.push(c)
      })
      return {
        todoCases: sortByUpdated(todoCases),
        inprogressCases: sortByUpdated(inprogressCases),
        doneCases: sortByUpdated(doneCases)
      }
    },
    _companyCases () {
      return this.companyCases
    },
    casesWithCompanyClient () {
      let companyClientCases = []
      if (this.filteredCases) {
        companyClientCases = this.filteredCases.filter(_case => _case.client ? _case.client.clientType === 'company' : false)
      }
      return companyClientCases
    },
    casesWithIndividualClient () {
      let individualClientCases = []
      if (this.filteredCases) {
        individualClientCases = this.filteredCases.filter(_case => _case.client ? _case.client.clientType === 'individual' : false)
      }
      return individualClientCases
    },
    recentlyAddedCases () {
      let recentCases = []
      if (this.filteredCases) {
        recentCases = sortByCreated(this.filteredCases).reverse()
      }
      return recentCases.reverse()
    }
  },
  mounted () {
    this.toggleDrawer(true)
  },
  async created () {
    await this.fetchCases()
  },
  methods: {
    ...mapActions({
      fetchCases: 'cases/fetchCases',
      updateCase: 'cases/updateCase',
      deleteCaseAction: 'cases/deleteCase'
    }),
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    changeView (item) {
      this.selection = item.type
      localStorage.setItem('casesSectionView', item.type)
    },
    parseCaseStatus (status) {
      return parseCaseStatus(status)
    },
    async changeStatus (caseFile, status) {
      await this.updateCase({
        _id: caseFile._id,
        payload: {
          status: status.value
        }
      })
    }
  }
}
</script>

<style scoped>
  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
<style>
  .bottom-element {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
</style>
