<template>
  <div>
    <!-- MOBILE -->
    <v-toolbar
      v-if="$vuetify.breakpoint.mobile"
      flat
      color="primary"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="accent"
      />
      <v-text-field
        :value="value"
        prepend-inner-icon="mdi-magnify"
        :label="$t('actions|search_cases')"
        dense
        hide-details
        dark
        @input="event => this.$emit('input', event)"
      />
      <v-menu
        v-if="account && account.accountType === 'lawyer'"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-filter-outline
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in categories"
            :key="index"
            @click="$emit('viewSelection', item)"
          >
            <v-list-item-title>{{ item.translation }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        v-if="options.hasAddCase"
        icon
        dark
        :disabled="!activeButton(account, company, group, actualTab)"
        @click="addNewCaseAction"
      >
        <v-icon>
          mdi-briefcase-plus
        </v-icon>
      </v-btn>
    </v-toolbar>

    <!-- DESKTOP -->
    <v-toolbar
      v-else
      flat
      class="mt-n3"
      color="lightBackground"
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="deep-purple accent-4"
      />
      <v-text-field
        :solo-inverted="soloInverted"
        :solo="solo"
        :value="value"
        prepend-inner-icon="mdi-magnify"
        :label="$t('actions|search_cases')"
        dense
        rounded
        hide-details
        class="shrink"
        @focus="soloInverted = true; solo = false"
        @blur="soloInverted = false; solo = true"
        @input="event => this.$emit('input', event)"
      />
      <v-menu
        v-if="account && account.accountType === 'lawyer'"
        rounded
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            outlined
            rounded
            dark
            small
            class="ml-2 pl-2 pr-1"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('common|filter') }}
            <v-icon class="ml-1">
              {{ icons.arrowDown }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in categories"
            :key="index"
            @click="$emit('viewSelection', item)"
          >
            <v-list-item-title>{{ item.translation }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer />
      <v-btn
        v-if="options.hasAddCase"
        color="primary"
        dense
        rounded
        small
        :disabled="!activeButton(account, company, group, actualTab)"
        @click="addNewCaseAction"
      >
        <v-icon
          small
          class="mr-2"
        >
          mdi-briefcase-plus
        </v-icon>
        {{ $t('cases|new_case') }}
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import { mdiChevronDown } from '@mdi/js'
import { EventBus } from '@/utils/EventBus'
import { userHasAccess } from '@/utils/utils'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({
        hasAddCase: true
      })
    },
    account: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    group: {
      type: Object,
      default: null
    },
    isCompanyTab: {
      type: Boolean,
      default: false
    },
    isGroupTab: {
      type: Boolean,
      default: false
    },
    isMyTab: {
      type: Boolean,
      default: false
    },
    isLavviraTab: {
      type: Boolean,
      default: false
    },
    isSingleAcc: {
      type: Boolean,
      default: false
    },
    isSharedWithMe: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: undefined
    },
    actualTab: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        arrowDown: mdiChevronDown
      },
      soloInverted: false,
      solo: true
    }
  },
  computed: {
    categories () {
      return [
        {
          type: 'companies',
          translation: this.$t('cases|cases_of_company_clients')
        },
        {
          type: 'individual_clients',
          translation: this.$t('cases|cases_of_individual_clients')
        },
        {
          type: 'recently_added',
          translation: this.$t('cases|recently_added_cases')
        },
        {
          type: 'all',
          translation: this.$t('common|all')
        }
      ]
    }
  },
  methods: {
    addNewCaseAction () {
      EventBus.$emit('dialog-add-case', this.actualTab)
    },
    activeButton (account, company, group, tab) {
      return userHasAccess(account, company, group, tab)
    }
  }
}
</script>
